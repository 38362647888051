<template>
  <b-card-group>
    <asset
      v-for="asset in lineAssets"
      :key="asset.assetID"
      :asset="asset"
    />
  </b-card-group>
</template>
<script>
import {
  BCardGroup,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import Asset from './Asset.vue'

export default defineComponent({
  props: {
    lineAssets: {
      type: Array,
      required: true,
    },
  },
  components: {
    Asset,
    BCardGroup,
  },
  setup(props) {
    if (props.lineAssets.length > 0) {
      props.lineAssets.sort((a, b) => a.assetID - b.assetID)
    }
  },
  watch: {
    lineAssets() {
      // sort assets by assetID
      this.lineAssets.sort((a, b) => a.assetID - b.assetID)
    },
  },
})
</script>
