<template>
  <b-card
    no-body
    class="m-0"
  >
    <!-- title and dropdown -->
    <!-- card body -->
    <b-card-body class="m-0 p-0" style="width: 100%">
      <b-container fluid>
        <b-row
          class="d-flex justify-content-center "
          align-v="center"
          style="width: 100%"
        >
 
          <!-- <b-link
              :to="{ name: 'apps-line-log', params: { 'lineID': lineAssets[0].lineID } }"
              class="vertical-align-middle"
              border rounded  flex-fill mb-0 mr-1 mt-2 text-center badge-minimal  position-relative d-inline-block
            > -->
          <b-card-group
            style="width: 100%;"
          >
            <b-card
              style="width: 100%"
              class="border rounded p-1 flex-fill mb-0 mr-1 mt-2 text-left badge-minimal  position-relative d-inline-block"
            >
              <blockquote class="blockquote mt-1 text-nowrap">
                <b-link
                  :to="{ name: 'apps-line-log', params: { 'lineID': lineAssets[0].lineID } }">
                  <p><strong class="text-black align-middle">{{ lineAssets[0].lineName }} </strong> </p>
                </b-link>
              </blockquote>
              <component
                :is="whichcomponent"
                v-if="whichcomponent != 'AlertsView' && whichcomponent != 'DownTimeOverview'"
                :line-assets="lineAssets"
              />
            </b-card>
          </b-card-group>
          </b-link>
    
          <b-col sm="10">

          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BCardGroup, BContainer, BLink, BButton, BButtonGroup, BIconClockHistory, BIconGraphUp, BIconBarChartLine, BIconBroadcastPin, BIconDiagram3, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import Ripple from 'vue-ripple-directive'
import AssetsView from './AssetsView.vue'
import LineOverview from './LineOverview.vue'
import Production from './Production.vue'
import AssetOverview from './AssetOverview.vue'
import DownTimeOverview from './DownTimeOverview.vue'


export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BContainer,
    BRow,
    BCol,
    BCardGroup,
    BLink,
    AssetsView,
    LineOverview,
    Production,
    AssetOverview,
    DownTimeOverview,
    BButton,
    BButtonGroup,
    BIconClockHistory,
    BIconGraphUp,
    BIconBarChartLine,
    BIconBroadcastPin,
    BIconDiagram3,
    BCardTitle,
    BCardHeader,
  },
  props: {
    lineAssets: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const assetsoverview = ref(false)
    const alertsview = ref(false)
    const { whichcomponent } = useAppConfig()
    return {
      assetsoverview,
      alertsview,
      whichcomponent,
    }
  },
}
</script>
