<template>
  <section
    id="dashboard-site"
  >
    <factory-status-legend />
    <building-lines
      :assets="siteAssets"
      :style="`zoom: ${zoom}%;`"
    />
  </section>
</template>

<script>
import store from '@/store/index'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FactoryStatusLegend from '@core/components/app-legends/FactoryStatusLegend.vue'
// eslint-disable-next-line import/no-cycle
import { getSiteRoutes } from '@/api/auth/utils'
import BuildingLines from './BuildingLines.vue'
import deviceStoreModule from './assetFactoryStore'

export default {
  components: {
    BuildingLines,
    FactoryStatusLegend,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-main'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, deviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const zoom = localStorage.getItem('vuexy-content-zoom') || 100

    return {
      zoom,
    }
  },
  data() {
    return {
      timer: null,
      siteAssets: [],
      siteID: window.location.pathname.split('/').pop(),
    }
  },
  watch: {
    $route() {
      this.siteID = window.location.pathname.split('/').pop()
    },
    siteID() {
      this.fetchAssets()
    },
  },
  created() {
    if (this.siteID === 'factory' || this.siteID === undefined || this.siteID === '') {
      const routes = getSiteRoutes()
      this.$router.push({ name: routes[0].name })
    }
  },
  mounted() {
    this.fetchAssets()
    if (this.timer) {
      clearInterval(this.timer)
      window.clearInterval(this.timer)
      clearTimeout(this.timer)
      console.log('clear')
    }
    this.timer = window.setInterval(() => {
      setTimeout(this.fetchAssets(), 0)
    }, 10000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      window.clearInterval(this.timer)
      clearTimeout(this.timer)
    }
  },
  methods: {
    fetchAssets() {
      store
        .dispatch('app-asset-main/fetchAllAssets')
        .then(response => {
          const { data } = response
          this.siteAssets = data.filter(item => item.siteID === this.siteID)
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
