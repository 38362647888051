<template>
  <section id="dashboard-buildinglines">
    <div
      v-if="whichcomponent != 'AlertsView' && whichcomponent != 'DownTimeOverview'"
    >
      <div>
        <b-card
          no-body
          class="overflow-hidden"
        >
          <b-row no-gutters>
            <b-col>
              <b-card-body>
                <b-card-text>
                  <div
                    v-for="[key, value] in groupedAssets"
                    :key="key"
                  >
                    <b-card
                      no-body
                      class="p-1"
                    >
                      <!-- title and dropdown -->
                      <b-card-header class="pb-0 mr-1 mb-2">
                        <b-card-title>
                          <strong class="text-black align-middle">{{ value[0].buildingName }} </strong>
                        </b-card-title>
                      </b-card-header>
                      <lines
                        :line-assets="value"
                      />
                    </b-card>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col
              v-if="whichcomponent == 'LineOverview'"
              md="4"
            >
              <div class="map">
                <gmap-map
                  ref="mapRef"
                  :center="mapConfig[assets[0].siteID.split('-').join('_')].center"
                  :zoom="mapZoom"
                  :options="mapStyle"
                  map-type-id="satellite"
                  style="height: 100%;"
                >
                  // eslint-disable-next-line vue/valid-v-for
                  <GmapMarker
                    v-for="(m, mIndex) in mapConfig[assets[0].siteID.split('-').join('_')].markers"
                    :key="`${assets[0].siteID.split('-').join('_')}_${mIndex}`"
                    :position="m.position"
                    :label="m.label"
                    :icon="m.icon"
                  />
                </gmap-map>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <div
      v-else-if="whichcomponent == 'AlertsView'"
    >
      <alerts-view
        :key="siteID"
        :site-i-d="siteID"
      />
    </div>
    <div
      v-else-if="whichcomponent == 'DownTimeOverview'"
    >
      <down-time-overview />
    </div>

  </section>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardImg, BCardText, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, onMounted } from '@vue/composition-api'
import Lines from './Lines.vue'
import AlertsView from './AlertsView.vue'
import DownTimeOverview from './DownTimeOverview.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    Lines,
    BCardHeader,
    AlertsView,
    DownTimeOverview,
    BCardImg,
    BCardText,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      groupedAssets: new Map(),
      siteID: '',
      mapZoom: 19,
      mapCenter: { lat: Number(2.687152), lng: Number(101.984559) },
      mapStyle: null,
      mapConfig: {
        d69d9f97_91c9_44d0_b957_7252130b8d14: {
          center: { lat: Number(2.687152), lng: Number(101.984559) },
          markers: [{
            position: {
              lat: 2.6870367916613143,
              lng: 101.98414661013135,
            },
            label: {
              text: 'U13',
              color: '#fff',
              fontSize: '14px',
            },
            icon: {
              // eslint-disable-next-line global-require
              url: require('@/assets/images/svg/normal-marker.svg'),
              scaledSize: {
                width: 50,
                height: 50,
              },
            },
          }, {
            position: {
              lat: 2.687264528731015,
              lng: 101.98447652181773,
            },
            label: {
              text: 'U2',
              color: '#fff',
              fontSize: '14px',
            },
            icon: {
              // eslint-disable-next-line global-require
              url: require('@/assets/images/svg/normal-marker.svg'),
              scaledSize: {
                width: 50,
                height: 50,
              },
            },
          }, {
            position: {
              lat: 2.6871151600106806,
              lng: 101.98449395617516,
            },
            label: {
              text: 'U6',
              color: '#fff',
              fontSize: '14px',
            },
            icon: {
              // eslint-disable-next-line global-require
              url: require('@/assets/images/svg/normal-marker.svg'),
              scaledSize: {
                width: 50,
                height: 50,
              },
            },
          }, {
            position: {
              lat: 2.686909526809739,
              lng: 101.98449127396631,
            },
            label: {
              text: 'U9',
              color: '#fff',
              fontSize: '14px',
            },
            icon: {
              // eslint-disable-next-line global-require
              url: require('@/assets/images/svg/normal-marker.svg'),
              scaledSize: {
                width: 50,
                height: 50,
              },
            },
          }],
        },
        b25f4156_647e_4903_a137_590477e45c3f: {
          center: { lat: Number(3.1231521245482745), lng: Number(101.445464551761289) },
          markers: [{
            position: {
              lat: 3.1231521245482745,
              lng: 101.445464551761289,
            },
            label: {
              text: 'Trial Line',
              color: '#fff',
              fontSize: '14px',
            },
            icon: {
              // eslint-disable-next-line global-require
              url: require('@/assets/images/svg/normal-marker.svg'),
              scaledSize: {
                width: 50,
                height: 50,
              },
            },
          }],
        },
      },
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: 'poly',
      },
    }
  },
  watch: {
    assets() {
      this.groupedAssets = this.groupBy(this.assets, asset => asset.buildingID)
      if (this.assets.length > 0) {
        this.siteID = this.assets[0].siteID
      }
    },
  },
  setup() {
    const { whichcomponent } = useAppConfig()
    return {
      whichcomponent,
    }
  },

  created() {
    this.groupedAssets = this.groupBy(this.assets, asset => asset.buildingID)
  },
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
  },
}
</script>
<style scoped>
.map{
        background-color: #ccc;
        border-radius: 10px 0px 0px 10px;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
</style>
