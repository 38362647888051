<template>
  <e-charts
    style="height: 10rem; width:100%;"
    :option="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, ToolboxComponent, VisualMapComponent, BrushComponent, MarkAreaComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  VisualMapComponent,
  BrushComponent,
  MarkAreaComponent,
])

const labelColorMap = {
  Sensoroffline: '#ea5455',
  Off: '#4b4b4b',
  Available: '#28c76f',
}

export default {
  components: {
    ECharts,
  },
  props: {
    graphdata: {
      type: Object,
      default: () => ([]),
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    legend: {
      type: Boolean,
      default: () => (true),
      required: false,
    },
    onlyPercent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [],
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          bottom: 80,
          show: this.legend,
          textStyle: {
            fontSize: 16,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
        },
        grid: this.legend ? {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        } : {
          left: '3%',
          right: '4%',
          bottom: 'none',
          top: 'none',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 16,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
        },
        yAxis: {
          type: 'category',
          show: false,
        },
        series: this.graphdata.seriesData.map(item => ({
          name: item.label,
          type: 'bar',
          stack: 'total',
          barWidth: 20,
          label: {
            show: false,
            position: 'insideRight',
            formatter: '{c}%',
            width: 50,
            overflow: 'truncate',
            ellipsis: '.',
          },
          data: [{
            value: this.calc(((item.duration / this.total) * 100)),
            itemStyle: {
              color: labelColorMap[item.label],
            },
          }],
        })),
      },
    }
  },
  methods: {
    calc(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },
  },
}
</script>
