<template>
  <section id="dashboard-building">
    <div
      v-for="[key, value] in groupedAssets"
      :key="key"
    >
      <factory-line
        :line-assets="value"
      />
      <!-- <line-health
        :line-assets="value"
      /> -->
    </div>
  </section>
</template>

<script>
import FactoryLine from './FactoryLine.vue'

export default {
  components: {
    FactoryLine,
  },
  props: {
    lineAssets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      groupedAssets: new Map(),
    }
  },
  watch: {
    lineAssets() {
      this.groupedAssets = this.groupBy(this.lineAssets, asset => asset.lineID)
    },
  },

  created() {
    this.groupedAssets = this.groupBy(this.lineAssets, asset => asset.lineID)
  },
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
  },
}
</script>
