<template>
  <div>
    <div class="d-flex justify-content-end">

    </div>
    <b-card
      no-body
      class="p-1"
    >
      <b-container
        fluid
        class="text-center"
      >

        <b-row>

          <b-col
            sm="9"
            class="mb-1 mt-1 d-flex justify-content-end"
          >
            <!-- <content-zoom-slider /> -->
            <b-button-group
              size="lg"
              style="background-color: white;"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'LineOverview'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'LineOverview'"
              >
                Availability
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'Production'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'Production'"
              >
                Production
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'AssetsView'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'AssetsView'"
              >
                Assets List
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'AssetOverview'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'AssetOverview'"
              >
                Assets Overview
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'AlertsView'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'AlertsView'"
              >
                Alerts
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :pressed="whichcomponent == 'DownTimeOverview'"
                variant="outline-primary"
                class="btn"
                @click="whichcomponent = 'DownTimeOverview'"
              >
                Downtime Overview
              </b-button>
            </b-button-group>
          </b-col>
          <b-col
            v-if="whichcomponent == 'LineOverview'"
            sm="3"
            class="mb-1 mt-1 d-flex justify-content-end"
          >
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="success"
              />Available
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="dark"
              />Off
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="danger"
              />Sensoroffline
            </span>
          </b-col>

          <b-col
            v-if="whichcomponent == 'AssetsView'"
            sm="6"
            class="mb-1 mt-1 d-flex justify-content-end"
          >
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-2 border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="white"
              />Sensor Self Test
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="dark"
              />Off
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="warning"
              />
              50% Speed
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="success"
              />100% Speed
            </span>
            <span class="ml-2">
              <b-icon-square-fill
                icon="square-fill"
                class="border border-grey rounded mr-1 align-middle"
                font-scale="2"
                variant="danger"
              />Anomaly
            </span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BContainer, BRow, BCol, BIcon, BCardTitle, BCardHeader, BButtonGroup, BButton, BIconSquareFill,
} from 'bootstrap-vue'
import ContentZoomSlider from '@core/layouts/components/app-navbar/components/ContentZoomSlider.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default ({
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    BIcon,
    ContentZoomSlider,
    BCardTitle,
    BCardHeader,
    BButtonGroup,
    BButton,
    BIconSquareFill,
  },
  setup() {
    const { whichcomponent } = useAppConfig()
    const alertsview = ref(false)
    return {
      whichcomponent,
      alertsview,
    }
  },
})
</script>

<style lang="scss">
// switchbutton position
.switch-button{
  position: relative;
  z-index: 1;
}

.ml-2 {
  margin-top: 1.2rem;
}
</style>