<template>
  <b-card class="p-0">
    <b-table
      :items="items"
      :fields="fields"
      :busy="isBusy"
      primary-key="id"
      :sticky-header="`90vh`"
      :no-border-collapse="noCollapse"
      class="p-0 m-0"
      responsive
    />
  </b-card>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  BTable, BCard
} from 'bootstrap-vue'
import store from '@/store/index'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  components: {
    BTable,
    BCard,
    ToastificationContent,
  },
  setup() {
    const toast = useToast()
    const isBusy = ref(false)
    const items = ref([])
    const fields = ref([
      {
        key: 'Start', stickyColumn: true, isRowHeader: true, label: 'Start',
      },
      {
        key: 'End', stickyColumn: true, isRowHeader: true, label: 'End', sortable: true, primary: true,
      },
      {
        key: 'ReportedDate', stickyColumn: true, isRowHeader: true, label: 'Reported', sortable: true,
      },
      {
        key: 'Line', stickyColumn: true, isRowHeader: true, label: 'Line', sortable: true,
      },
      {
        key: 'Reason', stickyColumn: true, isRowHeader: true, label: 'Type', sortable: true,
      },
      {
        key: 'Problem', stickyColumn: true, isRowHeader: true, label: 'Problem', sortable: true,
      },
      {
        key: 'Section', label: 'Section', sortable: false, filterable: true,
      },
      {
        key: 'Product', label: 'Product', sortable: false, filterable: true, 
      },
    ])

    const getItems = () => {
      isBusy.value = true
      store.dispatch('app-asset-main/fetchDowntimeRecords')
        .then(response => {
          items.value = response.data
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching records',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getItems()

    return {
      items,
      fields,
      isBusy,
    }
  },
})
</script>
