<template>
  <div>
    <magnitude-scatter-chart
      v-if="healthData.id"
      :key="healthData.id"
      :graphdata="healthData"
      :total="total"
      :only-percent="true"
    />
  </div>
</template>
<script>
import store from '@/store/index'
import { defineComponent, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MagnitudeScatterChart from '@core/components/charts/echart/MagnitudeScatterChart.vue'

export default defineComponent({
  components: {
    MagnitudeScatterChart,
  },
  props: {
    assetID: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const healthData = ref({ labels: [], data: [] })
    const total = ref(0)
    const now = new Date()
    const endTime = Date.now()
    const startTime = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).getTime()
    const fetchAssets = () => {
      store
        .dispatch('app-asset-main/fetchGraph', { assetID: props.assetID, type: 'magnitudetrend' })
        .then(response => {
          const { data } = response
          total.value = endTime - startTime
          healthData.value = data
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data from server',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAssets()

    return {
      healthData,
      total,
    }
  },
})
</script>
