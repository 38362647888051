<template>
  <div class="w-100">
    <vue-slider
      v-model="contentZoom"
    />
  </div>
</template>
<script>
import useAppConfig from '@core/app-config/useAppConfig'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    VueSlider,
  },
  setup() {
    const { isRTL, contentZoom } = useAppConfig()
    return { isRTL, contentZoom }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
