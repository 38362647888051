<template>
  <div
    :style="styleObj"
    class="mt-2 mb-2"
  >
    <e-charts
      style="height: 20rem; width:100%;"
      :option="option"
      theme="theme-color"
      auto-resize
    />
  </div>
</template>

<script>
import { msEpochToDate } from '@core/utils/filter'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { ScatterChart, EffectScatterChart, LineChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, ToolboxComponent, VisualMapComponent, BrushComponent, MarkAreaComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  ScatterChart,
  EffectScatterChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  VisualMapComponent,
  BrushComponent,
  MarkAreaComponent,
  LineChart,
])

export default {
  emits: ['brushEnd'],
  components: {
    ECharts,
  },
  props: {
    graphdata: {
      type: Object,
      default: null,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
  },
  data() {
    return {
      option: {
        xAxis: {
          min: this.graphdata.fromTime,
          max: this.graphdata.toTime,
          type: 'time',
          name: 'Time',
          axisLine: {
            onZero: false,
          },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
            formatter(value) {
              return msEpochToDate(value)
            },
          },
        },
        yAxis: {},
        visualMap: {
          type: 'piecewise',
          showLabel: true,
          dimension: 2,
          categories: this.graphdata.categories,
          textStyle: {
            fontSize: 16,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
          show: true,
          orient: 'horizontal',
          top: 10,
          left: 'center',
          color: [
            '#FF5722', // Orange
            '#4CAF50', // Green
            '#2196F3', // Blue
            '#FFC107', // Yellow
            '#9C27B0', // Purple
            '#F44336', // Red
            '#00BCD4', // Cyan
            '#673AB7', // Deep Purple
            '#FFEB3B', // Amber
            '#3F51B5', // Indigo
            '#009688', // Teal
            '#FF9800', // Deep Orange
            '#607D8B', // Blue Grey
            '#E91E63', // Pink
            '#795548', // Brown
            '#CDDC39', // Lime
            '#FF5722', // Orange
            '#4CAF50', // Green
            '#3F51B5', // Indigo
            '#FFC107', // Yellow
          ],
        },
        series: [
          {
            symbolSize: 3,
            data: this.graphdata.seriesData,
            type: 'scatter',
          },
        ],
      },
    }
  },

}
</script>
