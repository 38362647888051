<template>
  <div>
    <b-row
      v-for="asset in lineAssets"
      :key="asset.assetID"
      cols="2"
      align-v="center"
      align-h="start"
      align-content="start"
    >
      <b-col
        cols="2"
      >
        <asset
          :asset="asset"
        />
      </b-col>
      <b-col cols="10">
        <lazy>
          <asset-magnitude
            :asset-i-d="asset.assetID"
          />
        </Lazy>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import Lazy from '@core/components/lazy-load/Lazy.vue'
import Asset from './Asset.vue'
import AssetMagnitude from './AssetMagnitude.vue'

export default defineComponent({
  props: {
    lineAssets: {
      type: Array,
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    Asset,
    AssetMagnitude,
    Lazy,
  },
  setup(props) {
    if (props.lineAssets.length > 0) {
      props.lineAssets.sort((a, b) => a.assetID - b.assetID)
    }
  },
  watch: {
    lineAssets() {
      // sort assets by assetID
      this.lineAssets.sort((a, b) => a.assetID - b.assetID)
    },
  },
})
</script>
