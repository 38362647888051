<template>
  <div>
    <b-card
      style="width: 100%; height: 70vh;"
      class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
    >
      <div class="custom-search d-flex justify-content-end">
        <b-form-group class="m-1">
          <b-input-group size="m">
            <b-input-group-prepend>
              <b-button
                variant="outline-danger"

                @click="handleDeleteAll"
              >
                Mark All as Read
                <BIconCheck2All
                  class="check-2-all-icon text-danger"
                />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"

                @click="getItems"
              >
                Refresh
                <feather-icon
                  icon="RefreshCwIcon"
                />
              </b-button>
            </b-input-group-prepend>
            <date-picker
              v-model="timeRange"
              type="datetime"
              outlined
              value-type="timestamp"

              style="width: 370px"
              range
              :disabled="isBusy"
              class="border border-primary rounded"
              @clear="clearTimeRange"
              @close="validateTimeRange"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"

                @click="getItems"
              >
                Request Data
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"

                @click="downLoadFile"
              >
                <b-icon
                  icon="download"
                />
                DownLoad Data
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-table
          :id="tableID"
          sticky-header="55vh"
          show-empty
          :fields="fields"
          :items="items"
          primary-key="id"
          :busy="isBusy"
          sort-by="requestedAt"
          :sort-desc="true"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                class="align-middle"
                variant="primary"
              />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-danger"
              size="sm"
              @click="handleDelete(data.item)"
            >
              Mark as Read
              <BIconCheck2
                class="check-2 text-danger"
              />
            </b-button>
          </template>
          <template #cell(alertMessage)="data">
            <div>
              {{ data.item.alertMessage }} -
              <BIconBellFill
                v-if="!data.item.reviewed"
                class="text-danger"
              />
              <BIconBell v-if="data.item.reviewed" />
            </div>
          </template>
          <template #empty="scope">
            <h4>No data found</h4>
          </template>
          <template #emptyfiltered="scope">
            <h4>No data found</h4>
          </template>
        </b-table>
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            :aria-controls="tableID"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BIcon, BInputGroupPrepend, BTable, BPagination, BSpinner, BIconCheck2, BIconBellFill, BIconBell, BIconCheck2All,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from 'vue2-datepicker'
import Papa from 'papaparse'
import 'vue2-datepicker/index.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    DatePicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BIcon,
    BTable,
    BSpinner,
    BPagination,
    BIconCheck2,
    BIconCheck2All,
    BIconBell,
    BIconBellFill,
  },
  props: {
    siteID: {
      type: String,
      required: true,
    },
  },
  setup(props) {

    const now = new Date()
    const endTime = Date.now()
    const startTime = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).getTime()

    const defaultTimeRange = ref([endTime, startTime])
    const timeRange = ref(defaultTimeRange.value)
    const toast = useToast()
    const isBusy = ref(false)
    const asset = ref({})
    const items = ref([])
    const tableID = 'asset-alerts-list-table'
    const currentPage = ref(1)
    const perPage = ref(13)
    const rows = ref(0)
    const fields = [
      {
        key: 'assetID',
        label: 'Asset ID',
        sortable: true,
        type: 'number',
      },
      {
        key: 'msEpoch',
        label: 'Time',
        sortable: true,
        type: 'number',
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'alertType',
        label: 'Alert Type',
        sortable: true,
        type: 'string',
      },
      {
        key: 'faults',
        label: 'Faults',
      },
      {
        key: 'alertMessage',
        label: 'Alert Message',
        sortable: true,
        type: 'string',
      },
      {
        key: 'action', label: 'Action', sortable: false, filterable: false,
      },
    ]

    const getItems = () => {
      isBusy.value = true
      store.dispatch('app-asset-main/fetchAllAlerts', {
        siteID: props.siteID, startTime: timeRange.value[1], endTime: timeRange.value[0],
      })
        .then(response => {
          items.value = response.data
          rows.value = response.data.length
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching alerts list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getItems()

    const handleDelete = item => {
      isBusy.value = true
      store.dispatch('app-asset-log/reviewAlert', item)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Alert deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getItems()
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting alert',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const handleDeleteAll = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/reviewAlert', { id: 'all', typeID: router.currentRoute.params.assetID })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'All alerts deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getItems()
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting all alerts',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const clearTimeRange = () => {
      timeRange.value = [...defaultTimeRange.value]
      getItems()
    }

    const validateTimeRange = () => {
      if (timeRange.value.length === 2) {
        // check is range difference is less than 72 hour
        const diff = timeRange.value[1] - timeRange.value[0]
        if (diff > (72 * 3600000)) {
          toast('Please select a range of maximum 72 hour', {
            title: 'Invalid range',
            variant: 'danger',
            solid: true,
          })
          timeRange.value = [...defaultTimeRange.value]
        }
      } else {
        timeRange.value = [...defaultTimeRange.value]
      }
    }

    const toCSV = () => Papa.unparse(items.value)

    const downLoadFile = () => {
      console.log(items.value)
      const content = toCSV()
      const blob = new Blob([content], { type: 'application/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${asset.value.name}.csv`
      link.click()
      URL.revokeObjectURL(link.href)
    }

    return {
      getItems,
      isBusy,
      asset,
      items,
      currentPage,
      perPage,
      rows,
      tableID,
      fields,
      timeRange,
      clearTimeRange,
      validateTimeRange,
      downLoadFile,
      handleDelete,
      handleDeleteAll,
    }
  },
}
</script>
