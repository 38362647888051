<template>
  <div :style="styleObj">
    <e-charts
      v-if="data"
      autoresize
      :option="option"
      theme="theme-color"
      :style="styleObj"
      auto-resize
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart, BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, MarkLineComponent, ToolboxComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  GaugeChart,
  ToolboxComponent,
  BarChart,
  LineChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
    xAxisRotate: {
      type: Number,
      default: () => (0),
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      option: {
        grid: {
          height: '70%',
        },
        tooltip: {
          position: 'top',
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        title: {
          text: this.data.title || this.title || '',
          textStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
          subtext: this.data.subtitle || '',
          textAlign: 'center',
          textVerticalAlign: 'top',
          left: '45%',
        },
        xAxis: {
          type: 'category',
          data: this.data.xAxis,
          name: this.data.xAxisTitle || '',
          nameLocation: 'end',
          nameTextStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: [80, 30, 30, 30],
          },
          axisLabel: {
            interval: 0,
            rotate: this.xAxisRotate,
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
          },
        },
        yAxis: {
          type: 'value',
          name: this.data.yAxisTitle || '',
          nameRotate: 90,
          nameLocation: 'middle',
          nameTextStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: [30, 30, 50, 50],
          },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
          },
        },
        series: [
          {
            data: this.data.yAxis,
            type: 'bar',
            markLine: {
              data: [{ type: 'average', name: 'Avg' }],
              lineStyle: {
                color: '#f56c6c',
              },
            },
          },
        ],
      },
    }
  },
}
</script>
