<template>
  <e-charts
    :option="option"
    :style="styleObj"
    autoresize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { HeatmapChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, VisualMapComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  HeatmapChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          position: 'top',
        },
        grid: {
          height: '70%',
          top: '5%',
        },
        title: {
          text: this.data.title || this.title || '',
          textStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
          subtext: this.data.subtitle || '',
          textAlign: 'center',
          textVerticalAlign: 'top',
          left: '45%',
        },
        xAxis: {
          type: 'category',
          data: this.xAxis,
          splitArea: {
            show: true,
          },
          name: this.data.xAxisTitle || '',
          nameLocation: 'end',
          axisLabel: {
            interval: 0,
            rotate: 90,
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
          },
          nameTextStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: [50, 50, 50, 50],
          },
        },
        yAxis: {
          type: 'category',
          data: this.yAxis,
          splitArea: {
            show: true,
          },
          name: this.data.yAxisTitle || '',
          nameLocation: 'middle',
          nameTextStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: [30, 30, 50, 30],
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
          },
        },
        visualMap: {
          min: 0,
          max: 10,
          calculable: true,
          orient: 'vertical',
          right: '10',
          top: '15%',
        },
        series: [
          {
            type: 'heatmap',
            data: this.data.data.map(item => ([item[0], item[1].toString(), item[2] || '-'])),
            label: {
              show: true,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    }
  },

}
</script>
