<template>
  <div>
    <b-tabs content-class="mt-3" align="center">
      <b-tab title="Chart" active>
        <template #title>
          <b-icon-bar-chart scale="2" />
        </template>
        <b-card
          style="width: 100%; height: 70vh;"
          class="rounded p-0  mb-1 mr-1 mt-0"
        >
          <b-card-header class="pb-0 mr-1 mb-2">
            <b-card-title>
              <strong class="text-black align-left">Downtime Analysis</strong>
            </b-card-title>
          </b-card-header>
          <div>
            <b-row>
              <b-col cols="12">
                <heat-map
                  v-if="items.durationRectification"
                  :data="items.durationRectification"
                  :is-busy="isBusy"
                  :title="`Number of Problems vs Duration`"
                  :style-obj="{height: '68vh', width: '100%'}"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card
          style="width: 100%; height: 70vh;"
        >
          <div>
            <b-row>
              <b-col cols="12">
                <heat-map
                  v-if="items.problemfreqDistribution"
                  :data="items.problemfreqDistribution"
                  :is-busy="isBusy"
                  :title="`Number of Problems over Frequency of time`"
                  :style-obj="{height: '68vh', width: '100%'}"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card
          style="width: 100%; height: 70vh;"
          class="rounded p-0  mb-1 mr-1 mt-0"
        >
          <b-card-header class="pb-0 mr-1 mb-2">
            <b-card-title>
              <strong class="text-black align-left">Equipment Performance</strong>
            </b-card-title>
          </b-card-header>
          <div>
            <b-row>
              <b-col cols="6">
                <pie-chart
                  v-if="items.problemtypeCategory"
                  :data="items.problemtypeCategory.data.map(i => ({name: i[0], value: i[1]}))"
                  :labels="items.problemtypeCategory.xAxis"
                  :style-obj="{height: '60vh', width: '100%'}"
                  :title="`Number of Problems by Type`"
                />
              </b-col>
              <b-col cols="6">
                <bar-chart
                  v-if="items.lineDownTime"
                  :data="items.lineDownTime"
                  :style-obj="{height: '60vh', width: '100%'}"
                  :title="`Line downtime in minutes`"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card
          style="width: 100%; height: 70vh;"
          class="rounded p-0  mb-1 mr-1 mt-0"
        >
          <b-card-header class="pb-0 mr-1 mb-2">
            <b-card-title>
              <strong class="text-black align-left">Defect Analysis</strong>
            </b-card-title>
          </b-card-header>
          <div>
            <b-row>
              <b-col cols="12">
                <bar-chart
                  v-if="items.productDownTime"
                  :data="items.productDownTime"
                  :x-axis-rotate="90"
                  :style-obj="{height: '50vh', width: '100%'}"
                  :title="`Product downtime in minutes`"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card
          style="width: 100%; height: 70vh;"
          class="rounded p-0  mb-1 mr-1 mt-0"
        >
          <div>
            <b-row>
              <b-col cols="12">
                <bar-chart
                  v-if="items.problemCounts"
                  :data="items.problemCounts"
                  :style-obj="{height: '50vh', width: '100%'}"
                  :x-axis-rotate="90"
                  :title="`Problems Count`"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <b-tab title="Table">
        <template #title>
          <b-icon-table scale="2" />
        </template>
        <down-time-records />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BCard, BContainer, BRow, BCol, BFormSelect, BCardTitle, BCardHeader, BIconBarChart, BIconTable, BTabs, BTab,
} from 'bootstrap-vue'
import { defineComponent, ref } from '@vue/composition-api'
import store from '@/store/index'
import { useToast } from 'vue-toastification/composition'
import HeatMap from '@core/components/charts/echart/HeatMap.vue'
import BarChart from '@/@core/components/charts/echart/BarChart.vue'
import PieChart from '@/@core/components/charts/echart/PieChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DownTimeRecords from './DownTimeRecords.vue'

export default defineComponent({
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    HeatMap,
    BarChart,
    PieChart,
    BFormSelect,
    BCardTitle,
    BCardHeader,
    BIconBarChart,
    BIconTable,
    BTabs,
    BTab,
    DownTimeRecords,
  },
  setup() {
    const isBusy = ref(false)
    const items = ref([])
    const options = ref([])
    const selected = ref(0)
    const toast = useToast()
    const getItems = () => {
      isBusy.value = true
      store.dispatch('app-asset-main/fetchDowntimeStats')
        .then(response => {
          items.value = response.data
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching alerts list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getItems()
    return {
      isBusy,
      items,
      options,
      selected,
    }
  },
})
</script>
