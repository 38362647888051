import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAssets(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllAssets(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGraph(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/graph?assetID=${data.assetID}&graphType=${data.type}`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLinehealth(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/linehealth?assetID=${data.assetID}&startTime=${data.startTime}&endTime=${data.endTime}`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLineAvailability(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/availability?assetID=${data.assetID}&startTime=${data.startTime}&endTime=${data.endTime}`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllAlerts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/asset/allalerts?siteID=${data.siteID}&startTime=${data.startTime}&endTime=${data.endTime}`, { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDowntimeStats() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/downtimerecords/stats', { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDowntimeRecords() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/downtimerecords/list', { timeout: 200000 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
