<template>
  <div :style="styleObj">
    <e-charts
      v-if="graphData"
      autoresize
      :option="option"
      theme="theme-color"
      :style="styleObj"
      auto-resize
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart, BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, MarkLineComponent, ToolboxComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  GaugeChart,
  ToolboxComponent,
  BarChart,
  LineChart,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    graphData: {
      type: Array,
      default: null,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '800px',
      }),
    },
    xAxisRotate: {
      type: Number,
      default: () => (0),
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      colors: ['#5470C6', '#91CC75', '#EE6666'],
      option: {
        grid: {
          height: '65%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        title: {
          text: `Number of units produced ( ${this.graphData.map(item => item.totalUnits).reduce((accumulator, currentValue) => accumulator + currentValue, 0)} in last 30 days)`,
          textStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          },
          subtext: `Number of units per run - ${this.graphData[0].unitsPerLoop}`,
          textAlign: 'center',
          textVerticalAlign: 'top',
          left: '45%',
        },
        xAxis: {
          type: 'category',
          data: this.graphData.map(item => item.day),
          name: 'Day',
          nameLocation: 'end',
          nameTextStyle: {
            fontSize: 14,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: [80, 30, 30, 30],
          },
          axisLabel: {
            interval: 0,
            rotate: this.xAxisRotate,
            textStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'Number of Runs',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#5470C6',
              },
            },
            nameRotate: 90,
            nameLocation: 'middle',
            nameTextStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              padding: [30, 30, 50, 50],
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                fontSize: 14,
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              },
            },
          },
          {
            type: 'value',
            name: 'Number of Units',
            position: 'Left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#91CC75',
              },
            },
            nameRotate: 90,
            nameLocation: 'middle',
            nameTextStyle: {
              fontSize: 14,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              padding: [30, 30, 50, 50],
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                fontSize: 14,
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              },
            },
          },
        ],
        series: [
          {
            name: 'Number of Runs',
            type: 'bar',
            data: this.graphData.map(item => item.loops),
            markLine: {
              data: [{ type: 'average', name: 'Avg' }],
              lineStyle: {
                color: '#5470C6',
              },
            },
          },
          {
            name: 'Number of Units',
            type: 'bar',
            yAxisIndex: 1,
            data: this.graphData.map(item => item.totalUnits),
            markLine: {
              data: [{ type: 'average', name: 'Avg' }],
              label: {
                position: 'start',
              },
              lineStyle: {
                color: '#91CC75',
              },
            },
          },
        ],
      },
    }
  },
}
</script>
