<template>
  <e-charts
    :style="styleObj"
    :option="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    labels: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    data: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    onlyPercent: {
      type: Boolean,
      default: false,
    },
    styleObj: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '200px',
      }),
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : ({d}%)',
        },
        legend: {
          data: this.labels,
        },
        series: [
          {
            top: '15%',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            avoidLabelOverlap: true,
            label: {
              formatter: this.onlyPercent ? '{b}: {d}%' : '{b}-{c}: {d}%',
              padding: 4,
              fontSize: 10,
              overflow: 'break',
            },
          },
        ],
      },
    }
  },
}
</script>
